
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import i18n from '@/i18n';

import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import { useExpensesDagboekStore } from '@/store/pinia/dagboeken/dagboeken.expenses.store';

import DagboekLegendExpenses from './DagboekLegendExpenses.vue';
import DagboekExpense from './DagboekExpense.vue';
import DagboekSummary from '../shared/DagboekSummary.vue';

export default defineComponent({
    name: 'DagboekExpenses',
    components: {
        DagboekLegendExpenses,
        DagboekExpense,
        DagboekSummary
    },
    setup() {
        const { currentType, dagboekItemsUpdated } = storeToRefs(useDagboekenStore());

        const { dagboekExpenses } = storeToRefs(useExpensesDagboekStore());

        const noDailyReceiptsText = computed(() => {
            return `${i18n.t('dagboeken.empty_dagboeken')} ${i18n
                .t(`dagboeken.types.${currentType.value}`)
                .toString()
                .toLowerCase()}`;
        });

        return {
            i18n,
            currentType,
            dagboekExpenses,
            dagboekItemsUpdated,
            noDailyReceiptsText
        };
    }
});
