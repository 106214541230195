import { computed } from 'vue';
import { defineStore } from 'pinia';

import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import { useIncomesDagboekStore } from '@/store/pinia/dagboeken/dagboeken.incomes.store';
import { useExpensesDagboekStore } from '@/store/pinia/dagboeken/dagboeken.expenses.store';

import { DagboekExpense, DagboekIncome, DagboekMarSummary } from '@/views/dagboeken/dagboeken.types';

export const useDagboekSummaryStore = defineStore('dagboek.summary', () => {
    const dagboekenStore = useDagboekenStore();
    const incomesDagboekStore = useIncomesDagboekStore();
    const expensesDagboekStore = useExpensesDagboekStore();

    const addToMarSummary = (marValueMap: DagboekMarSummary, key: string, amount: number, marNumber: number) => {
        if (!marValueMap[key]) {
            marValueMap[key] = { marNumber: null, amount: 0 };
        }
        marValueMap[key].amount += amount;
        marValueMap[key].marNumber = marNumber;
    };

    const incomesMarSummary = computed<DagboekMarSummary>(() => {
        return incomesDagboekStore.dagboekIncomes.reduce((acc: DagboekMarSummary, income: DagboekIncome) => {
            addToMarSummary(acc, income.invoiceType, income.amountExclVat, income.marNumber as number);
            return acc;
        }, {} as DagboekMarSummary);
    });

    const expensesMarSummary = computed<DagboekMarSummary>(() => {
        return expensesDagboekStore.dagboekExpenses.reduce((acc: DagboekMarSummary, income: DagboekExpense) => {
            addToMarSummary(acc, income.grootboekrekening, income.costInAccounting, income.marNumber as number);
            return acc;
        }, {} as DagboekMarSummary);
    });

    const marSummary = computed(() => {
        if (dagboekenStore.isIncomesDagboek) {
            return incomesMarSummary.value;
        } else if (dagboekenStore.isExpensesDagboek) {
            return expensesMarSummary.value;
        } else {
            return {};
        }
    });

    const totalOfMarAmounts = computed<number>(() => {
        if (!marSummary.value) return 0;
        return Object.values(marSummary.value).reduce((acc, cur) => acc + cur.amount, 0);
    });

    const dagboekIncomesOrExpenses = computed<DagboekIncome[] | DagboekExpense[]>(() => {
        if (dagboekenStore.isIncomesDagboek) {
            return incomesDagboekStore.dagboekIncomes;
        } else {
            return expensesDagboekStore.dagboekExpenses;
        }
    });

    const bookingsSummary = computed(() => {
        const bookingValueMap: Record<string, number> = {};

        dagboekIncomesOrExpenses.value.forEach((currentIncomeOrExpense: DagboekIncome | DagboekExpense) => {
            currentIncomeOrExpense.bookings.forEach((booking) => {
                bookingValueMap[booking.raster] = (bookingValueMap[booking.raster] ?? 0) + booking.value;
            });
        });

        return bookingValueMap;
    });

    return {
        marSummary,
        totalOfMarAmounts,
        bookingsSummary
    };
});
