import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getEndDateFromQuarterAndYear, getStartDateFromQuarterAndYear } from '@/helpers/dateHelpers';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { useIncomesDagboekStore } from '@/store/pinia/dagboeken/dagboeken.incomes.store';
import { useExpensesDagboekStore } from '@/store/pinia/dagboeken/dagboeken.expenses.store';
import { useDailyReceiptsDagboekStore } from '@/store/pinia/dagboeken/dagboeken.dailyReceipts.store';

import { DateRange } from '@/types/general';
import { DagboekenType } from '@/views/dagboeken/dagboeken.types';

import { Q1, Q4, Quarters } from '@/config/constants';

export const useDagboekenStore = defineStore('dagboeken', () => {
    const type = ref<DagboekenType>();
    const typeIsMissing = ref<boolean>(false);
    const bookYear = ref<number>();
    const bookYearIsMissing = ref<boolean>(false);
    const useFullBookYear = ref<boolean>(true);
    const startQuarter = ref<Quarters>(Q1);
    const endQuarter = ref<Quarters>(Q4);

    const currentType = ref<DagboekenType>();
    const currentBookYear = ref<number>();
    const currentStartQuarter = ref<Quarters>(Q1);
    const currentEndQuarter = ref<Quarters>(Q4);

    const dagboekItemsUpdated = ref<number>(0);
    const dagboekenLoading = ref<boolean>(false);

    const isIncomesDagboek = computed<boolean>(() => currentType.value === 'incomes');
    const isExpensesDagboek = computed<boolean>(() => currentType.value === 'expenses');

    const setCurrentlyGeneratedParameters = (): void => {
        currentType.value = type.value;
        currentBookYear.value = bookYear.value;
        currentStartQuarter.value = startQuarter.value;
        currentEndQuarter.value = endQuarter.value;
    };

    const range = computed<DateRange>(() => {
        if (!currentBookYear.value) return { begin: '', end: '' };

        const begin = getStartDateFromQuarterAndYear(startQuarter.value, currentBookYear.value).format('YYYY-MM-DD');
        const end = getEndDateFromQuarterAndYear(endQuarter.value, currentBookYear.value).format('YYYY-MM-DD');

        return { begin, end };
    });

    const { generateIncomesDagboek } = useIncomesDagboekStore();
    const { generateExpensesDagboek } = useExpensesDagboekStore();
    const { generateDailyReceiptsDagboek } = useDailyReceiptsDagboekStore();

    const generateDagboek = async (): Promise<void> => {
        typeIsMissing.value = !type.value;
        bookYearIsMissing.value = !bookYear.value;

        // TODO: If end quarter is smaller than start quarter set error

        if (typeIsMissing.value || bookYearIsMissing.value) return;

        dagboekenLoading.value = true;

        // TODO: set values in query params ?
        setCurrentlyGeneratedParameters();

        try {
            switch (currentType.value) {
                case 'incomes':
                    await generateIncomesDagboek(range.value);
                    break;
                case 'expenses':
                    await generateExpensesDagboek(range.value);
                    break;
                case 'daily_receipts':
                    await generateDailyReceiptsDagboek(range.value);
                    break;
            }

            // Invalidate key in DagboekenDocument.vue and DagboekExpenses.vue so the document re-renders
            dagboekItemsUpdated.value += 1;
        } catch (error) {
            apiErrorAndDisplay(error);
        } finally {
            dagboekenLoading.value = false;
        }
    };

    return {
        type,
        typeIsMissing,
        bookYear,
        bookYearIsMissing,
        useFullBookYear,
        startQuarter,
        endQuarter,

        currentType,
        currentBookYear,
        currentStartQuarter,
        currentEndQuarter,

        isIncomesDagboek,
        isExpensesDagboek,

        generateDagboek,

        dagboekenLoading,
        dagboekItemsUpdated
    };
});
