
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import i18n from '@/i18n';

import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { convertToBelgianDate } from '@/helpers/dateHelpers';

import { DagboekExpense } from '@/views/dagboeken/dagboeken.types';

export default defineComponent<DagboekExpense>({
    name: 'DagboekExpense',
    props: {
        id: {
            type: Number,
            required: true
        },
        invoiceDate: {
            type: String,
            default: ''
        },
        supplierName: {
            type: String,
            default: ''
        },
        supplierType: {
            type: Number,
            default: 1
        },
        amountExclVat: {
            type: Number,
            default: 0
        },
        amountInclVat: {
            type: Number,
            default: 0
        },
        marNumber: {
            type: Number,
            default: 0
        },
        grootboekrekening: {
            type: String,
            default: ''
        },
        costInAccounting: {
            type: Number,
            default: 0
        },
        article56: {
            type: Boolean,
            default: false
        },
        bookings: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const router = useRouter();

        const openExpenseDocument = (): void => {
            const routeData = router.resolve({
                name: 'view-accounting-expense',
                params: { id: props.id.toString() }
            });

            if (routeData) window.open(routeData.href, '_blank');
        };

        const supplierTypeText = computed<string>(() => {
            switch (props.supplierType) {
                case 0:
                    return i18n.t('customer.private').toString();
                case 1:
                    return i18n.t('customer.belgian_company').toString();
                case 2:
                    return i18n.t('customer.company_inside_eu').toString();
                case 3:
                    return i18n.t('customer.company_outside_eu').toString();
                default:
                    return '';
            }
        });

        const formattedAmountExclVat = computed<string>(() => {
            return convertToLocaleValuta(props.amountExclVat, { withCurrencySign: false });
        });

        const formattedAmountInclVat = computed<string>(() => {
            return convertToLocaleValuta(props.amountInclVat, { withCurrencySign: false });
        });

        const formattedCostInAccounting = computed<string>(() => {
            return convertToLocaleValuta(props.costInAccounting, { withCurrencySign: false });
        });

        return {
            openExpenseDocument,
            convertToBelgianDate,
            supplierTypeText,
            formattedAmountExclVat,
            formattedAmountInclVat,
            formattedCostInAccounting,
            convertToLocaleValuta
        };
    }
});
