import { defaults } from 'lodash';

export function convertToLocaleValuta(
    value: number,
    options: Partial<{ currency: string; locale: string; withCurrencySign: boolean }> = {}
): string {
    const { locale, currency, withCurrencySign } = defaults(options, {
        currency: 'EUR',
        locale: 'nl-BE',
        withCurrencySign: true
    });

    return value.toLocaleString(locale, {
        style: withCurrencySign ? 'currency' : undefined,
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
}
