import { supplierType } from '@/config/constants';

import { DailyreceiptStatusType, IAccountingInvoiceViewModel } from 'dexxter-types';
import { IAccountingCreditnoteViewModel } from '@/services/accountingcreditnotes.types';
import { IAccountingExpenseViewModel } from '@/services/accountingexpenses.types';

const DagboekenTypes = ['incomes', 'expenses', 'daily_receipts'] as const;

type DagboekenType = typeof DagboekenTypes[number];

type WithBookings<T> = T & {
    bookings: Booking[];
};

type WithBookingsAndAccountingCostEuro<T> = WithBookings<T> & {
    accountingCostEuro: number;
};

type RawDagboekIncome = WithBookings<IAccountingInvoiceViewModel | IAccountingCreditnoteViewModel>;

type RawDagboekExpenses = WithBookingsAndAccountingCostEuro<IAccountingExpenseViewModel>;

interface Booking {
    value: number;
    raster: number;
}

interface DagboekIncome {
    id: number;
    invoiceNumber: string | null;
    invoiceDate: string | Date | null;
    customerName: string;
    customerType: supplierType;
    amountInclVat: number;
    marNumber: number | null;
    invoiceType: string;
    amountExclVat: number;
    article56: boolean;
    bookings: Booking[];
}

interface DagboekExpense {
    id: number;
    invoiceDate: string | Date | null;
    supplierName: string;
    supplierType: supplierType;
    amountExclVat: number;
    amountInclVat: number;
    marNumber: number | null;
    grootboekrekening: string;
    costInAccounting: number;
    article56: boolean;
    bookings: Booking[];
}

type DagboekDailyReceiptsByMonth = Record<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11, DagboekDailyReceiptsMonth>;

interface DagboekDailyReceiptsMonth {
    totalAmountExclVat: number;
    totalAmountInclVat: number;
    dagboekDailyReceipts: DagboekDailyReceipts;
}

interface DagboekDailyReceipts {
    [date: string]: DagboekDailyReceipt;
}

interface DagboekDailyReceipt {
    status: DailyreceiptStatusType;
    receipts: Receipt[];
}

interface Receipt {
    description: string;
    amountEuroExclVat: number;
    amountEuroVat: number;
}

type DagboekMarSummary = Record<string, { marNumber: number | null; amount: number }>;

export {
    RawDagboekIncome,
    RawDagboekExpenses,
    DagboekenTypes,
    DagboekenType,
    DagboekIncome,
    DagboekExpense,
    Receipt,
    DagboekDailyReceiptsByMonth,
    DagboekDailyReceipts,
    DagboekDailyReceipt,
    DagboekMarSummary,
    Booking
};
