import { defineStore } from 'pinia';
import { DagboekIncome, RawDagboekIncome } from '@/views/dagboeken/dagboeken.types';
import i18n from '@/i18n';
import { ICreditnoteViewModel } from '@/services/creditnote.types';
import { GrootboekrekeningNumbersIncome } from '@/config/constants';
import { sendGetIncomesDagboekenRequest } from '@/services/dagboeken';
import { computed, ref } from 'vue';
import { DateRange } from '@/types/general';

export const useIncomesDagboekStore = defineStore('dagboeken.incomes', () => {
    const rawDagboekIncomes = ref<RawDagboekIncome[]>([]);

    const isIncomeCreditNote = (income: RawDagboekIncome): boolean => {
        return Object.prototype.hasOwnProperty.call(income, 'creditnoteNumber');
    };

    const sortDagboekIncomesByInvoiceNumber = (dagboekIncomesOrExpenses: DagboekIncome[]) => {
        return dagboekIncomesOrExpenses.sort(({ invoiceNumber: invoiceNumberA }, { invoiceNumber: invoiceNumberB }) => {
            if (!invoiceNumberA || !invoiceNumberB) {
                return 0;
            } else {
                return invoiceNumberA.localeCompare(invoiceNumberB, i18n.locale, { numeric: true });
            }
        });
    };

    const mapRawDagboekIncomesToDagboekIncomes = (incomes: RawDagboekIncome[]): DagboekIncome[] => {
        let dagboekInvoices: DagboekIncome[] = [];
        let dagboekCreditNotes: DagboekIncome[] = [];

        incomes.forEach((income: RawDagboekIncome) => {
            const dagboekItem: DagboekIncome = {
                id: income.id,
                invoiceNumber: isIncomeCreditNote(income)
                    ? (income as ICreditnoteViewModel).creditnoteNumber
                    : income.invoiceNumber,
                invoiceDate: income.date,
                customerName: income.customer.company.name,
                customerType: income.customer.type,
                amountInclVat: isIncomeCreditNote(income) ? -income.amountEuroInclVAT : income.amountEuroInclVAT,
                marNumber: isIncomeCreditNote(income)
                    ? GrootboekrekeningNumbersIncome.income_creditnote
                    : GrootboekrekeningNumbersIncome.income_invoice,
                invoiceType: isIncomeCreditNote(income) ? 'creditnote' : 'invoice',
                amountExclVat: isIncomeCreditNote(income) ? -income.amountEuroExclVAT : income.amountEuroExclVAT,
                article56: income.article56,
                bookings: income.bookings
            };

            if (isIncomeCreditNote(income)) {
                dagboekCreditNotes.push(dagboekItem);
            } else {
                dagboekInvoices.push(dagboekItem);
            }
        });

        dagboekInvoices = sortDagboekIncomesByInvoiceNumber(dagboekInvoices);
        dagboekCreditNotes = sortDagboekIncomesByInvoiceNumber(dagboekCreditNotes);

        return [...dagboekInvoices, ...dagboekCreditNotes];
    };

    const dagboekIncomes = computed<DagboekIncome[]>(() => {
        return mapRawDagboekIncomesToDagboekIncomes(rawDagboekIncomes.value);
    });

    const generateIncomesDagboek = async (range: DateRange): Promise<void> => {
        rawDagboekIncomes.value = await sendGetIncomesDagboekenRequest(range);
    };

    return {
        generateIncomesDagboek,
        dagboekIncomes
    };
});
