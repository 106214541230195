
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import i18n from '@/i18n';
export default defineComponent({
    name: 'DagboekDocumentHeader',
    setup() {
        const { currentType, currentBookYear, currentStartQuarter, currentEndQuarter } = storeToRefs(
            useDagboekenStore()
        );

        const dagboekHeading = computed<string>(() => {
            return `${i18n.t('dagboeken.document_title')}: ${i18n
                .t('dagboeken.types.' + currentType.value)
                .toString()
                .toLocaleLowerCase()}`;
        });

        const dagboekParameters = computed(() => {
            return `${i18n.t('general.quarter')} ${currentStartQuarter.value[1]} ${i18n.t(
                'general.up_to_and_including'
            )} ${currentEndQuarter.value[1]}, ${currentBookYear.value}`;
        });

        return {
            dagboekHeading,
            dagboekParameters
        };
    }
});
