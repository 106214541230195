import {
    Q4,
    QuarterEndMonths,
    QuarterNumbers,
    Quarters,
    QuarterStartMonths,
    vatSubmissionFineStartDay,
    vatSubmissionMonths
} from '@/config/constants';
import dayjs, { Dayjs } from 'dayjs';
import { IVATSubmissionViewModel } from '@/views/VAT/models/models';
import { hasSubmittedVatListingForCurrentQuarter } from '@/helpers/VATHelper';
import { mod } from '@/helpers/mathHelpers';
import { IICSubmissionViewModel } from '@/services/viewmodels/general';
import '@/setup/dayjs';
import i18n from '@/i18n';

/**
 * Gets the current year as a String.
 */
export function getCurrentYearString(): string {
    return new Date().getFullYear().toString();
}

export function getCurrentDateString(): string {
    return dayjs().format('YYYY-MM-DD');
}

export function convertToBelgianDate(date: string): string {
    return dayjs.utc(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
}

export function getCurrentQuarterString(): Quarters {
    return getQuarterOfDate(getCurrentDateString());
}

export function getDayjsDateBelgianTimezone(date: string): Dayjs {
    if (!date) {
        return dayjs.utc().tz('Europe/Brussels');
    }
    return dayjs.tz(date, 'Europe/Brussels');
}

export function getQuarterOfDate(dateYYYYMMDDString: string): Quarters {
    const quarterNumber = getQuarterNumberOfDate(dateYYYYMMDDString);
    return getQuarterFromQuarterNumber(quarterNumber);
}

export function getQuarterNumberOfDate(dateYYYYMMDDString: string): QuarterNumbers {
    const month = dayjs(dateYYYYMMDDString, 'YYYY-MM-DD').month();

    let startMonth = 0;
    let endMonth = 2;

    for (let currentQuarterNumber = 1; currentQuarterNumber <= Quarters.length; currentQuarterNumber++) {
        if (startMonth <= month && month <= endMonth) {
            return currentQuarterNumber as QuarterNumbers;
        }

        startMonth += 3;
        endMonth += 3;
    }

    throw new Error('Cannot and should not happen');
}

export function getQuarterFromQuarterNumber(quarterNumber: number): Quarters {
    for (const [index, quarter] of Quarters.entries()) {
        const currentQuarterNumber = index + 1;

        if (currentQuarterNumber === quarterNumber) {
            return quarter;
        }
    }

    throw new Error('Cannot and should not happen');
}

export function getQuarterOfDateAndVatSubmissions(
    dateYYYYMMDDString: string,
    submissions: IVATSubmissionViewModel[] | IICSubmissionViewModel[]
): Quarters {
    const quarterNumber = getQuarterNumberFromDateAndVatSubmissions(dateYYYYMMDDString, submissions);
    return getQuarterFromQuarterNumber(quarterNumber);
}

function isVatSubmissionPeriod(day: number, month: number, quarter: Quarters): boolean {
    return day < vatSubmissionFineStartDay && month === vatSubmissionMonths[quarter];
}

function doesMonthOccurInCurrentQuarter(month: number, previousQuarter: Quarters, currentQuarter: Quarters) {
    return vatSubmissionMonths[previousQuarter] <= month && month < vatSubmissionMonths[currentQuarter];
}

export function getQuarterNumberFromDateAndVatSubmissions(
    dateYYYYMMDDString: string,
    submissions: IVATSubmissionViewModel[] | IICSubmissionViewModel[]
): number {
    const day = dayjs(dateYYYYMMDDString, 'YYYY-MM-DD').date();
    const month = dayjs(dateYYYYMMDDString, 'YYYY-MM-DD').month();
    const year = dayjs(dateYYYYMMDDString, 'YYYY-MM-DD').year();

    for (const [index, currentQuarter] of Quarters.entries()) {
        const currentQuarterNumber = index + 1;
        const previousQuarterIndex = index - 1;
        const previousQuarter = Quarters[mod(previousQuarterIndex, Quarters.length)];

        if (
            doesMonthOccurInCurrentQuarter(month, previousQuarter, currentQuarter) ||
            (isVatSubmissionPeriod(day, month, currentQuarter) &&
                !hasSubmittedVatListingForCurrentQuarter(submissions, currentQuarter, year))
        ) {
            return currentQuarterNumber;
        } else if (currentQuarter === Q4 && month >= vatSubmissionMonths.Q3) {
            return currentQuarterNumber;
        }
    }

    throw new Error('Cannot and should not happen');
}

export function getStartDateFromQuarterAndYear(quarter: Quarters, year: number): dayjs.Dayjs {
    const month = QuarterStartMonths[quarter];
    const day = 1;

    return dayjs(`${year}-${month}-${day}`, 'YYYY-M-D');
}

export function getEndDateFromQuarterAndYear(quarter: Quarters, year: number): dayjs.Dayjs {
    const month = QuarterEndMonths[quarter];
    const day = 1;

    return dayjs(`${year}-${month}-${day}`, 'YYYY-M-D').endOf('month');
}

export function getReadableDate(dateIsoString: string): string {
    return dayjs(dateIsoString).format("D MMM 'YY");
}

export function getReadableRelativeDateNotationFromIsoDateString(dateIsoString: string | Date): string {
    const dayjsDate = dayjs(dateIsoString);

    const today = dayjs();
    if (dayjsDate.isSame(today, 'day')) {
        return i18n.t('general.today').toString();
    }

    const yesterday = today.subtract(1, 'day');
    if (dayjsDate.isSame(yesterday, 'day')) {
        return i18n.t('general.yesterday').toString();
    }

    // TODO: Set global locale of dayjs
    const aWeekAgo = today.subtract(7, 'day');
    if (dayjsDate.isAfter(aWeekAgo)) {
        return dayjsDate.locale(i18n.locale).format('dddd');
    }

    return dayjsDate.locale(i18n.locale).format('D MMM');
}

export function getFormattedTimeFromIsoDateString(dateIsoString: string | Date): string {
    return dayjs(dateIsoString).format('HH:mm');
}

export function getMonthName(monthNumber: number | string): string {
    const parsedMonthNumber = parseInt(monthNumber.toString(), 10);

    const date = new Date();
    date.setMonth(parsedMonthNumber);

    return date.toLocaleString(i18n.locale, { month: 'long' });
}
