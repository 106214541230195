import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import dayjs from 'dayjs';

import { sendGetDailyReceiptsDagboekenRequest } from '@/services/dagboeken';
import { compareItemsBasedOnDate } from '@/views/communication/communication.helpers';

import { DagboekDailyReceipt, DagboekDailyReceiptsByMonth } from '@/views/dagboeken/dagboeken.types';
import { IDailyreceiptViewModel } from 'dexxter-types';
import { DateRange } from '@/types/general';

export const useDailyReceiptsDagboekStore = defineStore('dagboek.dailyReceipts', () => {
    const rawDailyReceipts = ref<IDailyreceiptViewModel[]>([]);

    const dagboekDailyReceiptsByMonth = computed<DagboekDailyReceiptsByMonth>(() => {
        const sortedRawDailyReceipts = rawDailyReceipts.value.sort(({ date: dateA }, { date: dateB }) => {
            return compareItemsBasedOnDate(new Date(dateA), new Date(dateB));
        });
        return mapDailyReceiptsToMonth(sortedRawDailyReceipts);
    });

    const createDagboekDailyReceipt = (dailyReceipt: IDailyreceiptViewModel): DagboekDailyReceipt => {
        return {
            status: dailyReceipt.status,
            receipts: [
                {
                    description: dailyReceipt.description,
                    amountEuroExclVat: dailyReceipt.amountEuroExclVAT,
                    amountEuroVat: dailyReceipt.amountEuroVAT
                }
            ]
        };
    };

    const addDailyReceiptDayToMonth = (
        dagboekDailyReceiptsByMonth: DagboekDailyReceiptsByMonth,
        dagboekDailyReceipt: IDailyreceiptViewModel
    ): DagboekDailyReceiptsByMonth => {
        const dateString = dagboekDailyReceipt.date;
        const month = dayjs(dateString, 'YYYY-MM-DD').month() as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

        if (!dagboekDailyReceiptsByMonth[month]) {
            dagboekDailyReceiptsByMonth[month] = {
                totalAmountExclVat: 0,
                totalAmountInclVat: 0,
                dagboekDailyReceipts: {}
            };
        }

        if (!dagboekDailyReceiptsByMonth[month].dagboekDailyReceipts[dateString]) {
            dagboekDailyReceiptsByMonth[month].dagboekDailyReceipts[dateString] =
                createDagboekDailyReceipt(dagboekDailyReceipt);
        } else {
            dagboekDailyReceiptsByMonth[month].dagboekDailyReceipts[dateString].receipts.push({
                description: dagboekDailyReceipt.description,
                amountEuroExclVat: dagboekDailyReceipt.amountEuroExclVAT,
                amountEuroVat: dagboekDailyReceipt.amountEuroVAT
            });
        }

        dagboekDailyReceiptsByMonth[month].totalAmountExclVat += dagboekDailyReceipt.amountEuroExclVAT;

        dagboekDailyReceiptsByMonth[month].totalAmountInclVat +=
            dagboekDailyReceipt.amountEuroExclVAT + dagboekDailyReceipt.amountEuroVAT;

        return dagboekDailyReceiptsByMonth;
    };

    const mapDailyReceiptsToMonth = (dailyReceipts: IDailyreceiptViewModel[]): DagboekDailyReceiptsByMonth => {
        const dagboekDailyReceiptsByMonth = {} as DagboekDailyReceiptsByMonth;

        dailyReceipts.forEach((dailyReceipt: IDailyreceiptViewModel) => {
            addDailyReceiptDayToMonth(dagboekDailyReceiptsByMonth, dailyReceipt);
        });

        return dagboekDailyReceiptsByMonth;
    };

    const generateDailyReceiptsDagboek = async (range: DateRange): Promise<void> => {
        rawDailyReceipts.value = await sendGetDailyReceiptsDagboekenRequest(range);
    };

    return {
        generateDailyReceiptsDagboek,
        dagboekDailyReceiptsByMonth
    };
});
