
import { defineComponent } from 'vue';
import i18n from '@/i18n';

export default defineComponent({
    name: 'DagboekLegendIncomes',
    setup() {
        return { i18n };
    }
});
