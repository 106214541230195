import axios from 'axios';
import { generateEndpoint } from '@/services/config';

import { RawDagboekExpenses, RawDagboekIncome } from '@/views/dagboeken/dagboeken.types';
import { IDailyreceiptViewModel } from 'dexxter-types';

import { DateRange } from '@/types/general';

export const BASE_URL_DAGBOEKEN = generateEndpoint('/api/dagboeken');

export async function sendGetIncomesDagboekenRequest(range: DateRange): Promise<RawDagboekIncome[]> {
    const { data } = await axios.get(`${BASE_URL_DAGBOEKEN}/income`, { params: range });

    return data;
}

export async function sendGetExpensesDagboekenRequest(range: DateRange): Promise<RawDagboekExpenses[]> {
    const { data } = await axios.get(`${BASE_URL_DAGBOEKEN}/expense`, { params: range });

    return data;
}

export async function sendGetDailyReceiptsDagboekenRequest(range: DateRange): Promise<IDailyreceiptViewModel[]> {
    const { data } = await axios.get(`${BASE_URL_DAGBOEKEN}/daily-receipt`, { params: range });

    return data;
}
