
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import i18n from '@/i18n';

import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import { useIncomesDagboekStore } from '@/store/pinia/dagboeken/dagboeken.incomes.store';

import DagboekLegendIncomes from './DagboekLegendIncomes.vue';
import DagboekIncome from './DagboekIncome.vue';
import DagboekSummary from '@/views/dagboeken/dagboeken-document/shared/DagboekSummary.vue';

export default defineComponent({
    name: 'DagboekIncomes',
    components: {
        DagboekLegendIncomes,
        DagboekIncome,
        DagboekSummary
    },
    setup() {
        const { currentType, dagboekItemsUpdated } = storeToRefs(useDagboekenStore());

        const { dagboekIncomes } = storeToRefs(useIncomesDagboekStore());

        const noDailyReceiptsText = computed(() => {
            return `${i18n.t('dagboeken.empty_dagboeken')} ${i18n
                .t(`dagboeken.types.${currentType.value}`)
                .toString()
                .toLowerCase()}`;
        });

        return {
            i18n,
            dagboekIncomes,
            dagboekItemsUpdated,
            noDailyReceiptsText
        };
    }
});
