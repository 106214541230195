
import { computed, defineComponent, onMounted, ref } from 'vue';
import i18n from '@/i18n';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { storeToRefs } from 'pinia';

import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import { sendGetNumberOfTaxesYearsRequest } from '@/services/tax';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { Q1, Q4, Quarters } from '@/config/constants';
import { DagboekenTypes } from '@/views/dagboeken/dagboeken.types';

export default defineComponent({
    name: 'DagboekenForm',
    setup() {
        const route = useRoute();
        const availableBookYears = ref<number[]>([]);

        onMounted(async () => {
            try {
                const unsortedAvailableYears = await sendGetNumberOfTaxesYearsRequest();
                availableBookYears.value = unsortedAvailableYears.sort((a, b) => b - a);
            } catch (e) {
                apiErrorAndDisplay(e);
            }
        });

        const title = computed<string>(() => {
            return i18n.t(route.meta?.title).toString();
        });

        const {
            type,
            typeIsMissing,
            bookYear,
            bookYearIsMissing,
            useFullBookYear,
            startQuarter,
            endQuarter,
            currentType,
            currentBookYear
        } = storeToRefs(useDagboekenStore());
        const { generateDagboek } = useDagboekenStore();

        const resetTypeIsMissing = () => {
            typeIsMissing.value = false;
        };

        const resetBookYearIsMissing = () => {
            bookYearIsMissing.value = false;
        };

        const dagboekenTypes = DagboekenTypes.map((dagboekenType) => {
            return {
                translation: i18n.t(`dagboeken.types.${dagboekenType}`),
                value: dagboekenType
            };
        });

        const quarterItems = Quarters.map((quarter, index) => {
            return {
                translation: `${i18n.t(`general.quarter`).toString()} ${index + 1}`,
                value: quarter
            };
        });

        function resetStartAndEndQuarter(newUseFullBookYear: boolean) {
            if (newUseFullBookYear) {
                startQuarter.value = Q1;
                endQuarter.value = Q4;
            }
        }

        const printDagboek = async () => {
            const printContent = document.getElementById('dagboek-page-simulation');

            // TODO: Only pass relevant css to print
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('style, link')]) {
                stylesHtml += node.outerHTML;
            }

            const printWindow = window.open(
                ' ',
                'Save for print',
                `left=0,top=0,right=0,bottom=0,toolbar=0,scrollbars=0,status=0`
            );

            if (printWindow && printContent) {
                let printHtml = printContent.innerHTML;

                printWindow.document.write(`<!DOCTYPE html>
                  <html>
                    <head>
                        <title>${i18n.t(`dagboeken.types.${currentType.value}`)}</title>
                      ${stylesHtml}
                    </head>
                    <body>`);
                printWindow.document.write(printHtml);
                printWindow.document.write('</body></html>');

                printWindow.addEventListener('load', () => {
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                });

                printWindow.document.close();
            }
        };

        return {
            i18n,
            title,
            type,
            typeIsMissing,
            resetTypeIsMissing,
            dagboekenTypes,
            bookYear,
            bookYearIsMissing,
            resetBookYearIsMissing,
            availableBookYears,
            useFullBookYear,
            resetStartAndEndQuarter,
            startQuarter,
            endQuarter,
            quarterItems,
            generateDagboek,
            currentType,
            currentBookYear,
            printDagboek
        };
    }
});
