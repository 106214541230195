
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';
import i18n from '@/i18n';

import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import { useDagboekSummaryStore } from '@/store/pinia/dagboeken/dagboeken.summary.store';

export default defineComponent({
    name: 'DagboekSummary',
    setup() {
        const { isIncomesDagboek } = storeToRefs(useDagboekenStore());

        const summaryHeading = computed(() => {
            return `${i18n.t('dagboeken.summary_heading')}`;
        });

        const marNumbersHeading = computed(() => {
            return `${i18n.t('dagboeken.mar_numbers_heading')}`;
        });

        const vatRastersHeading = computed(() => {
            return `${i18n.t('dagboeken.vat_rasters_heading')}`;
        });

        const { marSummary, totalOfMarAmounts, bookingsSummary } = storeToRefs(useDagboekSummaryStore());

        return {
            summaryHeading,
            marNumbersHeading,
            vatRastersHeading,
            bookingsSummary,
            marSummary,
            totalOfMarAmounts,
            isIncomesDagboek,
            convertToLocaleValuta,
            i18n
        };
    }
});
