import { DocumentCommunicationData, MessageData } from 'dexxter-types/communication';

type SortDirection = 'asc' | 'desc';

export function compareItemsBasedOnDate(
    createdAtA: Date,
    createdAtB: Date,
    chronologicalOrder: SortDirection = 'asc'
): number {
    const aDate = new Date(createdAtA).getTime();
    const bDate = new Date(createdAtB).getTime();

    if (chronologicalOrder === 'asc') {
        return aDate - bDate > 0 ? 1 : -1;
    } else {
        return bDate - aDate > 0 ? 1 : -1;
    }
}

export function sortMessages(unsortedMessages: MessageData[], direction: SortDirection = 'asc'): MessageData[] {
    return unsortedMessages.sort(({ createdAt: firstMessageCreatedAt }, { createdAt: secondMessageCreatedAt }) => {
        return compareItemsBasedOnDate(firstMessageCreatedAt, secondMessageCreatedAt, direction);
    });
}

export function sortMessagesInCommunications(
    communicationsWithUnsortedMessages: DocumentCommunicationData[]
): DocumentCommunicationData[] {
    communicationsWithUnsortedMessages.forEach((communication: DocumentCommunicationData) => {
        const { messages: unsortedMessages } = communication;
        communication.messages = sortMessages(unsortedMessages);
    });
    return communicationsWithUnsortedMessages;
}

export function sortCommunications(unsortedCommunications: DocumentCommunicationData[]): DocumentCommunicationData[] {
    return unsortedCommunications.sort(
        ({ messages: messagesA }: DocumentCommunicationData, { messages: messagesB }: DocumentCommunicationData) => {
            return compareItemsBasedOnDate(
                messagesA[messagesA.length - 1].createdAt,
                messagesB[messagesB.length - 1].createdAt,
                'desc'
            );
        }
    );
}

export function getRecipientMessage(messages: MessageData[], currentUserId: number): MessageData | undefined {
    return messages.find(({ senderId }) => senderId !== currentUserId);
}

export function getRecipientName(
    messages: MessageData[],
    currentUserId: number,
    accountantCompanyName: string | undefined
): string {
    const { senderName } = getRecipientMessage(messages, currentUserId) || {};

    if (senderName) {
        return senderName;
    } else if (accountantCompanyName) {
        return accountantCompanyName;
    }

    return '';
}

export function getInitialsFromFullName(fullName: string): string {
    const splitName = fullName.split(' ');

    if (splitName.length > 1) {
        return `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(0)}`;
    } else {
        return splitName[0].charAt(0);
    }
}
