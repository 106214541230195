import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { sendGetExpensesDagboekenRequest } from '@/services/dagboeken';
import { compareItemsBasedOnDate } from '@/views/communication/communication.helpers';

import { DagboekExpense, RawDagboekExpenses } from '@/views/dagboeken/dagboeken.types';
import { DateRange } from '@/types/general';

export const useExpensesDagboekStore = defineStore('dagboek.expenses', () => {
    const rawDagboekExpenses = ref<RawDagboekExpenses[]>([]);

    const mapExpensesToDagboekItems = (expenses: RawDagboekExpenses[]): DagboekExpense[] => {
        return expenses.map((expense) => {
            return {
                id: expense.id,
                invoiceDate: expense.date,
                supplierName: expense.supplier.company.name,
                supplierType: expense.supplier.type,
                amountExclVat: expense.isCreditnote ? -expense.amountEuroExclVAT : expense.amountEuroExclVAT,
                amountInclVat: expense.isCreditnote ? -expense.amountEuroInclVAT : expense.amountEuroInclVAT,
                marNumber: expense.expensecategory.number,
                grootboekrekening: expense.expensecategory.name,
                costInAccounting: expense.isCreditnote ? -expense.accountingCostEuro : expense.accountingCostEuro,
                article56: expense.article56,
                bookings: expense.bookings
            };
        });
    };

    const sortDagboekExpensesByDate = (unsortedDagboekExpenses: DagboekExpense[]): DagboekExpense[] => {
        return unsortedDagboekExpenses.sort(({ invoiceDate: invoiceDateA }, { invoiceDate: invoiceDateB }) => {
            if (!invoiceDateA || !invoiceDateB) return 0;
            return compareItemsBasedOnDate(new Date(invoiceDateA), new Date(invoiceDateB));
        });
    };

    const dagboekExpenses = computed<DagboekExpense[]>(() => {
        const unsortedDagboekExpenses = mapExpensesToDagboekItems(rawDagboekExpenses.value);
        return sortDagboekExpensesByDate(unsortedDagboekExpenses);
    });

    const generateExpensesDagboek = async (range: DateRange): Promise<void> => {
        rawDagboekExpenses.value = await sendGetExpensesDagboekenRequest(range);
    };

    return {
        generateExpensesDagboek,
        dagboekExpenses
    };
});
