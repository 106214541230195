
import { defineComponent, ref, watch } from 'vue';
import DagboekDocument from '@/views/dagboeken/dagboeken-document/DagboekDocument.vue';
import DagboekenForm from '@/views/dagboeken/dagboeken-form/DagboekenForm.vue';
import { storeToRefs } from 'pinia';
import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import DagboekLoading from '@/views/dagboeken/DagboekLoading.vue';

export default defineComponent({
    name: 'DagboekenPage',
    components: { DagboekLoading, DagboekDocument, DagboekenForm },
    setup() {
        const outerWrapper = ref<HTMLDivElement>();

        const { dagboekItemsUpdated, dagboekenLoading } = storeToRefs(useDagboekenStore());

        watch(dagboekItemsUpdated, () => {
            if (outerWrapper.value) {
                outerWrapper.value.scrollTop = 0;
            }
        });

        return { outerWrapper, dagboekenLoading };
    }
});
