
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import i18n from '@/i18n';

import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';
import { convertToBelgianDate, getMonthName } from '@/helpers/dateHelpers';
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { useDailyReceiptsDagboekStore } from '@/store/pinia/dagboeken/dagboeken.dailyReceipts.store';

export default defineComponent({
    name: 'DagboekDailyReceiptsTable',
    setup() {
        const { currentType, currentBookYear } = storeToRefs(useDagboekenStore());
        const { dagboekDailyReceiptsByMonth } = storeToRefs(useDailyReceiptsDagboekStore());

        const noDailyReceiptsText = computed(() => {
            return `${i18n.t('dagboeken.empty_dagboeken')} ${i18n.t(`dagboeken.types.${currentType.value}`)}`;
        });

        return {
            dagboekDailyReceiptsByMonth,
            currentBookYear,
            convertToLocaleValuta,
            convertToBelgianDate,
            i18n,
            noDailyReceiptsText
        };
    },
    methods: { getMonthName }
});
