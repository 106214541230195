
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import i18n from '@/i18n';

import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { convertToBelgianDate } from '@/helpers/dateHelpers';

import { DagboekIncome } from '@/views/dagboeken/dagboeken.types';

export default defineComponent<DagboekIncome>({
    name: 'DagboekIncome',
    props: {
        id: {
            type: Number,
            required: true
        },
        invoiceNumber: {
            type: String,
            default: ''
        },
        invoiceDate: {
            type: String,
            default: ''
        },
        customerName: {
            type: String,
            default: ''
        },
        customerType: {
            type: Number,
            default: 1
        },
        amountInclVat: {
            type: Number,
            default: 0
        },
        marNumber: {
            type: Number,
            default: 0
        },
        invoiceType: {
            type: String,
            default: ''
        },
        amountExclVat: {
            type: Number,
            default: 0
        },
        article56: {
            type: Boolean,
            default: false
        },
        bookings: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const router = useRouter();

        const openIncomeDocument = (): void => {
            const routeData = router.resolve({
                name: 'accounting-invoicable-view',
                params: { type: props.invoiceType, id: props.id.toString() }
            });

            if (routeData) window.open(routeData.href, '_blank');
        };

        const customerTypeText = computed<string>(() => {
            switch (props.customerType) {
                case 0:
                    return i18n.t('customer.private').toString();
                case 1:
                    return i18n.t('customer.belgian_company').toString();
                case 2:
                    return i18n.t('customer.company_inside_eu').toString();
                case 3:
                    return i18n.t('customer.company_outside_eu').toString();
                default:
                    return '';
            }
        });

        const formattedAmountInclVat = computed<string>(() => {
            return convertToLocaleValuta(props.amountInclVat, { withCurrencySign: false });
        });

        const invoiceTypeText = computed<string>(() => {
            return i18n.t(`general.${props.invoiceType}`).toString();
        });

        const formattedAmountExclVat = computed<string>(() => {
            return convertToLocaleValuta(props.amountExclVat, { withCurrencySign: false });
        });

        return {
            openIncomeDocument,
            convertToBelgianDate,
            customerTypeText,
            formattedAmountInclVat,
            invoiceTypeText,
            formattedAmountExclVat,
            convertToLocaleValuta
        };
    }
});
