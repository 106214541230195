
import { computed, defineComponent } from 'vue';
import i18n from '@/i18n';
import { storeToRefs } from 'pinia';

import { useDagboekenStore } from '@/store/pinia/dagboeken/dagboeken.store';

import DagboekDocumentHeader from '@/views/dagboeken/dagboeken-document/DagboekDocumentHeader.vue';
import DagboekIncomes from '@/views/dagboeken/dagboeken-document/incomes/DagboekIncomes.vue';
import DagboekExpenses from '@/views/dagboeken/dagboeken-document/expenses/DagboekExpenses.vue';
import DagboekDailyReceiptsTable from '@/views/dagboeken/dagboeken-document/dagontvangsten/DagboekDailyReceiptsTable.vue';

export default defineComponent({
    name: 'DagboekDocument',
    components: { DagboekDailyReceiptsTable, DagboekDocumentHeader, DagboekIncomes, DagboekExpenses },
    setup() {
        const { currentType, isIncomesDagboek, isExpensesDagboek, currentBookYear, dagboekItemsUpdated } = storeToRefs(
            useDagboekenStore()
        );

        const hasGeneratedDagboek = computed(() => {
            return currentType.value && currentBookYear.value;
        });

        return {
            i18n,
            hasGeneratedDagboek,
            isIncomesDagboek,
            isExpensesDagboek,
            dagboekItemsUpdated
        };
    }
});
